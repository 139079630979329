import PageHeader from '@components/PageHeader';
import React, { Component } from 'react';
import {
  Button,
  ButtonToolbar,
  Form,
  Input,
  InputPicker,
  IconButton,
  toaster,
  Message,
  Checkbox,
} from 'rsuite';
import { FaPen, FaTimesCircle } from 'react-icons/fa';
import { ModalCreateOrEditCustomer } from '../../components/Modals/Customer/ModalCreateOrEditCustomer';
import { Avatar, Panel, PanelGroup, Placeholder } from 'rsuite';
import { FormattedMessage } from 'react-intl';

let flagDefaultValues = true
export class AddEditCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = { contactModal: false, customFields: { } };
    
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onCloseModal() {
    this.setState({
      contactModal: false,
      actionModal: '',
    });
  }
  render() {
    const {
      formValues,
      formAction,
      cities,
      citiesContact,
      states,
      channels,
      invoicingSettings,
      listPrice,
      customerIdType,
      paymentMethods,
      paymentTerms,
      fiscalResponsibility,
      taxRegime,
      costCenter,
      withholdings,
      fulfillmentTypeId,
      defaultLocationId,
      actionOnShipment,
      citiesDisabled,
      onChange,
      onErrorValidation,
      onSubmitAndCreate,
      onSubmit,
      onCancel,
      onStatesSearch,
      onStatesChange,
      onCitiesChange,
      onIdTypeChange,
      model,
      onCitiesChangeContact,
      onStatesChangeContact,
      onErrorValidationContact,
      onChangeContact,
      formValuesContact,
      contactFormModel,
      contactModel,
      editContactContainer,
      setValuesForm,
      deleteContactContainer,
      stateModal,
      defaultAdress,
      handleCheckAll,
      active,
      fields,
      createCustomerOrder,
      ...props
    } = this.props;

    const action = formAction == 'CREATE' ? 'Crear' : 'Editar';

    const deleteContact = (contact, position) => {
      deleteContactContainer(position);
    };

    const costCenterDefault = costCenter?.find(
      item => item.isDefault === true,
    );
    if (costCenterDefault?.id && !this.props.formValues.costCenter) {
      this.props.formValues.costCenter = costCenterDefault?.id
    }
    const fulfillmentTytpeDefault = fulfillmentTypeId?.find(
      item => item.isDefault === true,
    );
    if (fulfillmentTytpeDefault?.id && !this.props.formValues.fulfillmentTypeId) {
      this.props.formValues.fulfillmentTypeId = fulfillmentTytpeDefault?.id
    }
    const defaultLocationIdDefault = defaultLocationId?.find(
      item => item.isDefault === true,
    );
    if (defaultLocationIdDefault?.id && !this.props.formValues.defaultLocationId) {
      this.props.formValues.defaultLocationId = defaultLocationIdDefault?.id
    }

    const editContact = (contact, position) => {
      stateModal(position, 'edit');
      editContactContainer(contact);
      contactFormModel();
      this.setState({
        contactModal: true,
        actionModal: 'edit',
      });
    };

    const openModalContact = () => {
      stateModal(0, 'create');
      contactFormModel();
      this.setState({
        contactModal: true,
        actionModal: 'create',
      });
    };

    const onClose = () => {
      setValuesForm();
      this.onCloseModal();
    };

    const handleCheckAllCustomer = (value, checked) => {
      handleCheckAll(checked)
    }
    const handleDynamicFieldChange = (fieldName, value) => {
      flagDefaultValues = false
      this.setState({
        customFields: {
          ...this.state.customFields,
          [fieldName]: value,
        },
      });
      this.props.formValues.customFields = {
        ...this.props.formValues?.customFields,
        [fieldName]: value,
      }
    };
    const renderDynamicFields = () => {
      return fields?.map((field, index) => {
        if (formAction === 'CREATE' && (!this.props.formValues?.customFields || !Array.isArray(this.props.formValues?.customFields))) {
          if (!this.props.formValues?.customFields?.[field?.title]) {
            if (field?.defaultValue) {
              this.props.formValues.customFields = {
                ...this.props.formValues?.customFields,
                [field?.title]: field?.defaultValue,
              }
              if (flagDefaultValues === true) {
                this.setState((prevState) => {
                  return {
                    customFields: {
                      ...prevState.customFields,
                      [field?.title]: field?.defaultValue,
                    },
                  };
                });
              }
            }
          }
        } else {
          if (Array.isArray(this.props.formValues?.customFields) && this.props.formValues?.customFields.length > 0) {
            const nuevoObjeto = {};
            this.props.formValues?.customFields?.forEach(item => {
              const newKey = item.title
              nuevoObjeto[newKey] = item.value;
            });
            this.props.formValues.customFields = nuevoObjeto
            this.setState((prevState) => {
              return {
                customFields: nuevoObjeto
              };
            });
          }
        }
        switch (field?.fieldType) {
          case 'INPUT':
            return (
              <div key={index} >
                <TextField
                  name={field?.title}
                  label={field?.title}
                  defaultValue={!(formAction !== 'CREATE') ? field?.defaultValue : undefined}
                  value={this.state.customFields?.[field?.title]}
                  required={field?.mandatory}
                  onChange={value => handleDynamicFieldChange(field?.title, value)}
                />
              </div>
            );
          case 'LIST':
            return (
              <div key={index} >
                <DropDownField
                  name={field?.title}
                  label={field?.title}
                  data={field?.values.map(({ label, value }) => ({ label, value }))}
                  required={field?.mandatory}
                  value={this.state.customFields?.[field?.title]}
                  defaultValue={!(formAction !== 'CREATE') ? field?.defaultValue : undefined}
                  onChange={(selectedValue) => handleDynamicFieldChange(field?.title, selectedValue)}
                  {...props}
                />
              </div>
            );
          default:
            return null;
        }
      });
    };
    return (
      <div className=" pb-1">
        {!createCustomerOrder && (
          <PageHeader title={`${action} cliente`} />
        )}
        <PanelGroup className="mx-8" accordion bordered>
          <Panel header="Información General" defaultExpanded>
            <Form
              fluid
              ref={ref => (this.form = ref)}
              onChange={formValue => {
                onChange(formValue);
              }}
              onCheck={formError => {
                onErrorValidation(formError);
              }}
              formValue={formValues}
              model={model}>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2 px-8">
                <div>
                  <DropDownField
                    name="idType"
                    label="Tipo identificación "
                    data={customerIdType}
                    onChange={onIdTypeChange}
                    {...props}
                  />
                </div>
                <div>
                  <TextField name="identification" label="Identificación" />
                </div>
                <div>
                  <TextField name="name" label="Nombres" />
                </div>
                <div>
                  <TextField disabled={formValues?.idType === 'NIT' ? true : false} name="lastName" label="Apellidos" />
                </div>
                {formValues?.idType === 'NIT' && (
                  <div>
                    <TextField name="brandName" label="Nombre Comercial" />
                  </div>
                )}
                <div>
                  <TextField name="mobile" label="Teléfono celular" />
                </div>
                <div>
                  <TextField name="phone" label="Teléfono fijo" />
                </div>
                <div>
                  <TextField name="primaryEmail" label="e-mail" />
                </div>
                <div>
                  <TextAreaField rows={2} name="address" label="Dirección" />
                </div>
                <div>
                  <DropDownField
                    name="stateId"
                    label="Departamento"
                    onSearch={onStatesSearch}
                    onChange={onStatesChange}
                    disabled={false}
                    data={states}
                    {...props}
                  />
                </div>
                <div>
                  <DropDownField
                    name="cityId"
                    label="Ciudad"
                    data={cities}
                    disabled={citiesDisabled}
                    onChange={onCitiesChange}
                    {...props}
                  />
                </div>
                <div>
                  <DropDownField
                    name="paymentMethodId"
                    label="Forma de pago"
                    data={paymentMethods}
                    {...props}
                  />
                </div>
                <div>
                  <DropDownField
                    name="paymentTerms"
                    label="Plazo de pago"
                    data={paymentTerms}
                    {...props}
                  />
                </div>
                <div>
                  <DropDownField
                    name="listPriceId"
                    label="Lista de precio"
                    data={listPrice}
                    {...props}
                  />
                </div>
                <div>
                  <DropDownField
                    name="fiscalResponsibility"
                    label="Responsabilidad tributaria"
                    data={fiscalResponsibility}
                    {...props}
                  />
                </div>
                <div>
                  <DropDownField
                    name="taxRegime"
                    label="Régimen tributario"
                    data={taxRegime}
                    {...props}
                  />
                </div>
                <div>
                  <DropDownField
                    name="channelId"
                    label="Canal principal"
                    data={channels}
                    {...props}
                  />
                </div>
                <div>
                  <DropDownField
                    name="invoicingSettingId"
                    label="Numeración de facturación"
                    data={invoicingSettings}
                    {...props}
                  />
                </div>
                <div>
                  <DropDownField
                    name="actionOnShipment"
                    label="Acción al despacho"
                    data={actionOnShipment}
                    {...props}
                  />
                </div>
              </div>
              <div className='ml-6'>
                {active && (
                  <Checkbox defaultChecked onChange={handleCheckAllCustomer}> Activo</Checkbox>
                )}
                {!active && (
                  <Checkbox onChange={handleCheckAllCustomer}> Activo</Checkbox>
                )}
              </div>
            </Form>
          </Panel>
          <Panel header="Información Contacto">
            <div className="md:px-8 lg:px-8 ">
              {formValues?.contacts?.map((contact, index) => (
                <div className="flex lg:w-2/4" key={index}>
                  <div
                    className={
                      contact?.defaultShippingAddress
                        ? 'flex md:w-1/5 lg:w-1/2 p-1 font-black '
                        : 'flex md:w-1/5 lg:w-1/2 p-1'
                    }>
                    <div>
                      <Avatar
                        style={{ background: contact?.color }}
                        className=" w-6 h-6 mr-3"
                        circle>
                        {contact?.name?.charAt(0)}
                      </Avatar>
                    </div>
                    <div>
                      {contact?.name} {contact?.lastName}
                    </div>
                  </div>
                  <div
                    className={
                      contact?.defaultShippingAddress
                        ? 'flex md:w-1/5 lg:w-1/2 p-1 font-black '
                        : 'flex md:w-1/5 lg:w-1/2 p-1'
                    }>
                    <div>
                      {contact?.city?.name}
                      {', '}
                      {contact?.state?.name}
                    </div>
                  </div>
                  <div className="flex md:w-1/5 lg:w-1/5 p-1 ">
                    <IconButton
                      size="sm"
                      icon={<FaPen />}
                      title="Editar"
                      onClick={() => editContact(contact, index)}></IconButton>
                    <IconButton
                      size="sm"
                      icon={<FaTimesCircle />}
                      title="Eliminar"
                      onClick={() =>
                        deleteContact(contact, index)
                      }></IconButton>
                  </div>
                </div>
              ))}
              <Button appearance="primary" onClick={() => openModalContact()}>
                Crear Contacto
              </Button>
            </div>
          </Panel>
          <Panel header="Configuración">
            <Form
              fluid
              onChange={formValue => {
                onChange(formValue);
              }}
              onCheck={formError => {
                onErrorValidation(formError);
              }}
              formValue={formValues}
              model={model}>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2 px-8">
                <div>
                  <DropDownField
                    name="defaultLocationId"
                    label="Origen de despachos"
                    data={defaultLocationId?.map(
                      ({
                        id,
                        name,
                      }) => ({
                        label: name,
                        value: id,
                      }),
                    )}
                    {...props}
                  />
                </div>
                <div>
                  <DropDownField
                    name="fulfillmentTypeId"
                    label="Tipo logística"
                    data={fulfillmentTypeId?.map(
                      ({
                        id,
                        description,
                      }) => ({
                        label: description,
                        value: id,
                      }),
                    )}
                    {...props}
                  />
                </div>
                <div>
                  <DropDownField
                    name="costCenter"
                    label="Centro de costos"
                    data={costCenter?.map(
                      ({
                        id,
                        name,
                      }) => ({
                        label: name,
                        value: id,
                      }),
                    )}
                    {...props}
                  />
                </div>
                <div>
                  <DropDownField
                    name="withholdings"
                    label={<FormattedMessage id="withholdings" />}
                    data={withholdings?.map(
                      ({
                        id,
                        description,
                        percentage,
                        idERP,
                      }) => ({
                        label: `${description} - ${percentage}%`,
                        value: id
                      }),
                    )}
                    {...props}
                  />
                </div>
              </div>
            </Form>
          </Panel>
          <Panel header="Campos dinámicos">
            <Form
              fluid
            >
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2 px-8">
                  {renderDynamicFields()}
              </div>
            </Form>
          </Panel>
        </PanelGroup>

        <div>
          <ModalCreateOrEditCustomer
            visible={this.state.contactModal}
            onStatesSearch={onStatesSearch}
            onStatesChange={onStatesChangeContact}
            states={states}
            cities={citiesContact}
            props={props}
            citiesDisabled={citiesDisabled}
            onCitiesChange={onCitiesChangeContact}
            formValues={formValuesContact}
            model={contactModel}
            onChange={onChangeContact}
            onSubmit={onSubmit}
            onErrorValidation={onErrorValidationContact}
            onCloseModal={onClose}
            actionModal={this.state.actionModal}
            defaultAdress={defaultAdress}
          />
        </div>
        <div className="flex px-4 pt-8">
          <div>
            <ButtonToolbar>
              <Button
                appearance="primary"
                onClick={() => onSubmit(this.form, false, false, this.state.customFields, createCustomerOrder)}>
                Guardar
              </Button>
              {formAction === 'CREATE' && !createCustomerOrder && (
                <Button onClick={() => onSubmit(this.form, true, false, this.state.customFields, createCustomerOrder)}>
                  Guardar y crear otro
                </Button>
              )}
              <Button onClick={onCancel}>Cancelar</Button>
            </ButtonToolbar>
          </div>
          <div></div>
        </div>
      </div>
    );
  }
}

export class TextField extends React.PureComponent {
  render() {
    const { name, label, accepter, tooltip, tooltipText, ...props } =
      this.props;
    return (
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <div className="flex items-center">
          <Form.Control name={name} accepter={accepter} {...props} />
          {tooltip && <Form.HelpText tooltip className='mt-0 mr-2'>{tooltipText}</Form.HelpText>}
        </div>
      </Form.Group>
    );
  }
}

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));

export class TextAreaField extends React.PureComponent {
  render() {
    const { name, label, accepter, tooltip, tooltipText, rows, ...props } =
      this.props;
    return (
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Form.Control
          name="textarea"
          rows={rows}
          name={name}
          accepter={Textarea}
          {...props}
        />
        {tooltip && <Form.HelpText tooltip >{tooltipText}</Form.HelpText>}
      </Form.Group>
    );
  }
}

export class DropDownField extends React.PureComponent {
  render() {
    const {
      name,
      label,
      data,
      plaintext,
      value,
      accepter,
      tooltip,
      tooltipText,
      onSearch,
      onChange,
      disabled,
      ...props
    } = this.props;
    return (
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Form.Control
          name={name}
          onSearch={onSearch}
          onChange={onChange}
          accepter={InputPicker}
          cleanable={false}
          data={data}
          disabled={disabled}
          value={value}
          style={{ width: '100%' }}
          {...props}
        />
        {tooltip && <Form.HelpText tooltip>{tooltipText}</Form.HelpText>}
      </Form.Group>
    );
  }
}

import apiListPriceProduct from "@api/ListPriceProductApi";
import history from "@router/history";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { Message, toaster } from "rsuite";
import * as actions from "../actions";
import types from "../actions/ActionTypes";
import api from "../api/CustomerApi";
import * as constants from "../utils/Constants";
import * as CustomerMapper from "../utils/Mappers/CustomerMappers";
import {
  CUSTOMER_CREATED_FAILURE,
  CUSTOMER_CREATED_SUCCESS,
  CUSTOMER_EDITED_SUCCESS,
  GENERAL_ERROR_MESSAGE
} from "../utils/Notifications";
import { rollbarErrors } from "@utils/rollbarErrors";

function* createCustomer(action) {

  const fullDataValidation = action.payload.fullDataValidation;
  const newCustomer = CustomerMapper.mapCreateCustomerDto(
    action.payload.customer,
    fullDataValidation,
  );
  const companyId = action.payload?.loggingParams?.companyId
  const email = action.payload?.loggingParams?.user

  try {
    yield put(actions.setShowSpinner(true));
    const response = yield call(api.saveCustomer, newCustomer);
    if (action.payload.onSuccess) {
      action.payload.onSuccess(response.data);
    }
    toaster.push(
      <Message
        type="success"
        showIcon
        closable
        duration={constants.NOTIFICATION_DURATION}
      >
        {CUSTOMER_CREATED_SUCCESS}
      </Message>
    );
    yield put(actions.getCustomers());
    if (action.payload?.redirect) {
      action.payload.createNew
      ? yield call(history.push, "addEdit-customer")
      : yield call(history.push, "list-customers");
    }
    return response?.data
  } catch (error) {
    rollbarErrors(error, { customer: newCustomer }, 'POST/customers', companyId, email)

    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      const message = error?.response.data.message
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          {`${CUSTOMER_CREATED_FAILURE}: ${message} `}
        </Message>
      );
      console.error('oups, an error has occured!', error);
    }
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

function* editCustomer(action) {
  const fullDataValidation = action.payload.fullDataValidation;
  const companyId = action.payload?.loggingParams?.companyId
  const email = action.payload?.loggingParams?.user

  const { id, ...editedCustomer } = CustomerMapper.mapCreateCustomerDto(
    action.payload.customer,
    fullDataValidation,
  );

  try {
    yield put(actions.setShowSpinner(true));
    yield call(api.editCustomer, id, editedCustomer);

    if (!action.payload.contact) {
      toaster.push(
        <Message
          type="success"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          {CUSTOMER_EDITED_SUCCESS}
        </Message>
      );
      yield put(actions.getCustomers());
      yield call(history.push, "list-customers");
    }
  } catch (error) {
    rollbarErrors(error, { id, editedCustomer }, 'PATCH/customers', companyId, email)
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      const message = error?.response.data.message
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
          {`${CUSTOMER_CREATED_FAILURE}: ${message} `}
        </Message>
      );
      console.error('oups, an error has occured!', error);
    }
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

function* updateCustomer(action) {
  try {
    let customer = action.payload;

    if (customer) {
      const listPriceProduct = yield call(apiListPriceProduct.getListPrice, {
        listPriceId: customer.listPriceId,
      });

      if (listPriceProduct.status === 401) {
        yield put(actions.setOpenAuthModal({ open: true }));
      } else  {
        yield put(actions.updateSelectedPriceList(listPriceProduct));
      }
    }
    yield put(actions.updateSelectedCustomer(customer));
  } catch (error) {
    console.error(error);
  }
}

function* redirectCreateCustomer(action) {
  yield put(actions.updateFormAction({ form: "CUSTOMER", action: "CREATE" }));
  yield call(history.push, "addEdit-customer");
}

function* loadEditCustomer(action) {
  try {
    yield put(actions.setShowSpinner(true));
    const { data } = yield call(api.getCustomerById, action.payload.id);

    data.cityId = (data.city && data.city.id) || ''
    data.stateId = (data.state && data.state.id) || ''
    data.costCenter = data?.costCenter?.id
    data.withholdings = data?.withholdings?.id
    data.fulfillmentTypeId = data.fulfillmentType?.id
    yield put(actions.updateEditedCustomer(data));
    yield put(actions.updateFormAction({ form: "CUSTOMER", action: "EDIT" }));
    yield call(history.push, "addEdit-customer");
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    } else {
      const message = error?.response.data.message;
      console.error(error);
      toaster.push(
        <Message
          type="error"
          showIcon
          closable
          duration={constants.NOTIFICATION_DURATION}
        >
        {`${CUSTOMER_CREATED_FAILURE}: ${message} `}
        </Message>
      );
      console.error('oups, an error has occured!', error);
    }
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

function* searchCustomers({ payload: { source, ...filters }}) {
  try {
    if (source === "list-customers") {
      yield put(actions.setShowSpinner(true));
    }

    const response = yield call(api.searchCustomers, filters);

    yield put(
      actions.updateCustomersPaging({
        name: "totalRows",
        value: response.data.total,
      })
    );

    if (source === "list-customers") {
      yield put(
        actions.updateGridCustomers(
          CustomerMapper.mapCustomersList(response.data.items)
        )
      );
    } else {
      yield put(
        actions.updateStateCustomers(
          CustomerMapper.mapCustomersList(response.data.items)
        )
      );
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      console.log(error.response.data.message);
    } else if (error?.response?.status === 401) {
      yield put(actions.setOpenAuthModal({ open: true }));
    }
    console.error('oups, an error has occured!', error);
  } finally {
    yield put(actions.setShowSpinner(false));
  }
}

export default function* () {
  yield all([
    takeLatest(types.CHANGE_ORDER_CUSTOMER, updateCustomer),
    takeLatest(types.CREATE_CUSTOMER, createCustomer),
    takeLatest(types.REDIRECT_CREATE_CUSTOMER, redirectCreateCustomer),
    takeLatest(types.LOAD_EDIT_CUSTOMER, loadEditCustomer),
    takeLatest(types.EDIT_CUSTOMER, editCustomer),
    takeLatest(types.SEARCH_CUSTOMERS, searchCustomers),
  ]);
}
